// VotingPage.js
import { useState, useEffect, useCallback } from "react";
import { Modal, Button, Card, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import TopBar from "./TopBar";
//const BASE_URL = "https://evoting-backend.qtsoftwareltd.com:4435";
import { BASE_URL } from "./config";
const VotingPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { no, code, positionDetail } = location?.state || {};
  // State to manage the list of candidates and user selections
  const [loading, setLoading] = useState(false);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [responseMessage, setResponseMessage] = useState(null);

  // State to manage the timer
  const [timer, setTimer] = useState(
    positionDetail?.position?.session_duration
  );

  const [showModal, setShowModal] = useState(false);
  const [showSkipModal, setSkipShowModal] = useState(false);
  const [showResponseModal, setshowResponseModal] = useState(false);

  const handleCheckboxChange = (candidateId) => {
    const allowedVotes = positionDetail.position.no_of_selections;
    const mandatoryFemaleVotes = Math.ceil(
      (positionDetail.position.no_of_selections_female / 100) * allowedVotes
    );

    const selectedMaleVotes = selectedCandidates.filter(isMaleCandidate).length;

    // If only one selection is allowed, deselect the previously selected candidate
    if (allowedVotes === 1) {
      setSelectedCandidates([candidateId]);
      //  setShowModal(true);
      return;
    }

    // Toggle the selected status of the candidate
    setSelectedCandidates((prevSelected) => {
      const newSelected = prevSelected.includes(candidateId)
        ? prevSelected.filter((id) => id !== candidateId)
        : [...prevSelected, candidateId];

      const newSelectedMaleVotes = newSelected.filter(isMaleCandidate).length;

      // Check if the selection violates any rules
      if (
        newSelected.length > allowedVotes ||
        (isMaleCandidate(candidateId) &&
          newSelectedMaleVotes > allowedVotes - mandatoryFemaleVotes)
      ) {
        // If the selection violates the rules, display an alert and do not update the state
        console.log(
          " newSelected: " +
            newSelected +
            ", allowedVotes: " +
            allowedVotes +
            ", allowedVotes: " +
            allowedVotes +
            ", mandatoryFemaleVotes: " +
            mandatoryFemaleVotes
        );
        alert(
          isMaleCandidate(candidateId) &&
            mandatoryFemaleVotes != 0 &&
            selectedCandidates.length !=
              positionDetail.position.no_of_selections
            ? "Umubare w'abagabo wemerewe gutora wageze\nYou cannot select more male candidates."
            : `Umubare w'abakandida wemerewe gutora ni ${allowedVotes}, wageze\nYou cannot select more than ${allowedVotes} candidates.`
        );
        return prevSelected;
      }
      return newSelected;
    });
  };

  const isMaleCandidate = (candidateId) => {
    return positionDetail?.candidates_male?.some(
      (maleCandidate) => maleCandidate.id === candidateId
    );
  };

  const handleskip = async () => {
    setSkipShowModal(true);
  };
  // Function to handle submit button click
  const handleSubmit = async () => {
    // Check if the user has selected the required percentage of female candidates
    // const femaleCount = selectedCandidates.filter((id) =>
    //   candidates.some(
    //     (candidate) => candidate.id === id && candidate.gender === "Female"
    //   )
    // ).length;

    if (
      selectedCandidates.length !== positionDetail?.position?.no_of_selections
    ) {
      alert(
        positionDetail?.position?.no_of_selections_female === 0
          ? `Please select ${positionDetail?.position?.no_of_selections} ${
              positionDetail?.position?.no_of_selections === 1
                ? "candidate"
                : "candidates"
            }.\nHakenewe ko uhitamo ${
              positionDetail?.position?.no_of_selections == 1
                ? "umukandida"
                : "abakandida"
            } ${positionDetail?.position?.no_of_selections}.`
          : `Please select ${
              positionDetail?.position?.no_of_selections
            } candidates with at least ${calculateRequiredFemaleCount()} female candidates.\nUmubare w'abagomba gutorwa ni ${
              positionDetail?.position?.no_of_selections
            } harimo byubuze abagore${calculateRequiredFemaleCount()}`
      );
      return;
    }

    // Show confirmation modal
    setShowModal(true);
  };

  const isFemaleCandidate = (candidateId) => {
    return positionDetail.candidates_female.some(
      (femaleCandidate) => femaleCandidate.id === candidateId
    );
  };

  const isVoteValid = () => {
    const allowedVotes = positionDetail.no_of_selections;
    const mandatoryFemaleVotes = Math.ceil(
      (positionDetail.no_of_selections_female / 100) * allowedVotes
    );

    const selectedFemaleVotes =
      selectedCandidates.filter(isFemaleCandidate).length;
    const selectedMaleVotes = selectedCandidates.filter(isMaleCandidate).length;

    return (
      selectedCandidates.length === allowedVotes &&
      selectedFemaleVotes >= mandatoryFemaleVotes &&
      selectedMaleVotes <= allowedVotes - mandatoryFemaleVotes
    );
  };

  // Function to calculate the required percentage of female candidates
  const calculateRequiredFemaleCount = () => {
    return positionDetail?.no_of_selections_female === 0
      ? 0
      : Math.ceil(
          (positionDetail?.no_of_selections_female / 100) *
            positionDetail?.no_of_selections
        );
  };

  // Function to handle skip button click
  const handleSkip = async () => {
    try {
      setLoading(true);
      const postData = {
        no,
        code,
        candidates: [],
        status: "skipped",
      };
      // Call API with user selections
      const response = await submitVote(postData);

      // Check if the API call was successful
      if (response.success) {
        // You can perform additional actions here if needed
        console.log("Vote submitted successfully!");
      } else {
        // Handle API error
        console.error("Error submitting vote:", response.error);
        // You can show an error message if needed
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleTimeOut = async () => {
    try {
      setLoading(true);
      const postData = {
        no,
        code,
        candidates: selectedCandidates,
        status: "timeout",
      };
      // Call API with user selections
      const response = await submitVote(postData);

      // Check if the API call was successful
      if (response.success) {
        // You can perform additional actions here if needed
        console.log("Vote submitted successfully!");
      } else {
        // Handle API error
        console.error("Error submitting vote:", response.error);
        // You can show an error message if needed
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  // Function to handle modal confirmation
  const handleModalConfirm = async () => {
    try {
      // Toggle loading state
      setLoading(true);

      const postData = {
        no: no,
        code: code,
        candidates: selectedCandidates,
        status: "success",
      };
      // Call API with user selections
      const response = await submitVote(postData);

      // Check if the API call was successful
      if (response.success) {
        // You can perform additional actions here if needed
        console.log("Vote submitted successfully!");
      } else {
        // Handle API error
        console.error("Error submitting vote:", response.error);
        // You can show an error message if needed
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  // Function to handle modal cancel

  const handleModalCancel = () => {
    setLoading(false);
    setShowModal(false);
    setSkipShowModal(false);
  };

  const handleModalCancel2 = () => {
    navigate("/");
    setshowResponseModal(false);
  };

  // Function to call the API for submitting the vote
  const submitVote = async (voteData) => {
    try {
      console.log("voteData", voteData);
      const response = await axios.post(
        `${BASE_URL}/v1/mobile/submit`,
        voteData
      );
      console.log("response", response);
      if (response.data.message?.toLowerCase().includes("have already voted")) {
        setResponseMessage(`${response.data.data.message}\nWashoje Gutora`);
      } else {
        setResponseMessage(response.data.data.submit_instructions);
      }
      setshowResponseModal(true);
    } catch (error) {
      console.log("data1", error.message);
      setResponseMessage(error.message);
      setshowResponseModal(true);
    } finally {
      setShowModal(false);
    }
  };

  // useEffect to update the timer
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        const newTimer = prevTimer - 1;

        // Check if the timer reaches 0
        if (newTimer === 0) {
          handleTimeOut();
        }

        return newTimer;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(remainingSeconds).padStart(2, "0")}`;
  };

  const getCandidateById = (candidateId) => {
    // Search in the female candidates array
    const femaleCandidate = positionDetail?.candidates_female?.find(
      (candidate) => candidate.id === candidateId
    );

    // If found in the female candidates array, return the candidate
    if (femaleCandidate) {
      return femaleCandidate;
    }

    // If not found in the female candidates array, search in the male candidates array
    const maleCandidate = positionDetail?.candidates_male?.find(
      (candidate) => candidate.id === candidateId
    );

    // Return the candidate found in the male candidates array (or undefined if not found)
    return maleCandidate;
  };

  return (
    <div className="text-center">
      <TopBar />
      {
        <div>
          <header className="d-flex justify-content-between align-items-center mb-4">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "20px",
              }}
            >
              <img
                src="/rwanda_img.svg"
                alt="Logo"
                style={{ width: "60px", height: "60px", marginRight: "10px" }}
              />
              <div>
                <h2 style={{ marginBottom: "2px" }}>PARLIAMENT</h2>{" "}
                {/* Reduced bottom margin */}
                <p
                  style={{
                    margin: 0,
                    fontSize: "12px",
                    fontFamily: "Comic Sans MS",
                  }}
                >
                  E-Voting Platform
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src="/Voting_logo.png"
                alt="Logo"
                style={{
                  width: "60px",
                  height: "60px",
                  marginBottom: "10px",
                  marginRight: "10px",
                }}
              />
              <span
                style={{
                  margin: 0,
                  fontSize: "12px",
                  fontFamily: "Comic Sans MS",
                }}
              >
                Amatora
              </span>
            </div>
          </header>
        </div>
      }
      <p
        style={{
          marginBottom: "10px",
          fontSize: "18px",
          fontFamily: "Comic Sans MS",
          fontWeight: "bold",
        }}
      >
        {positionDetail?.position?.name}
      </p>

      <p
        style={{
          margin: 0,
          fontSize: "12px",
          fontFamily: "Comic Sans MS",
        }}
      >
        Remaining Time
      </p>
      {
        <p
          style={{
            margin: 0,
            fontSize: "12px",
            fontFamily: "Comic Sans MS",
          }}
        >
          Iminota Usigaranye: {formatTime(timer)}
        </p>
      }
      {
        <p
          style={{
            margin: 0,
            fontSize: "12px",
            fontFamily: "Comic Sans MS",
          }}
        >
          Umubare w'abakandida | No Candidates :{" "}
          {positionDetail?.candidates_male.length +
            positionDetail?.candidates_female.length}
        </p>
      }
      {(positionDetail?.candidates_male.length > 0 ||
        positionDetail?.candidates_female.length > 0) && (
        <div className="text-center">
          <div className="d-flex flex-wrap justify-content-center">
            {positionDetail?.candidates_male
              .concat(positionDetail?.candidates_female)
              .map((candidate) => (
                <Card
                  key={candidate.id}
                  style={{
                    width: "10rem",
                    margin: "5px",
                    border: selectedCandidates.includes(candidate.id)
                      ? "2px solid blue"
                      : "1px solid black",
                    borderRadius: "8px",
                  }}
                >
                  <Card.Img
                    variant="top"
                    src={`${BASE_URL}/v1/mobile/download?path=${candidate?.profile_picture_path}`}
                    onError={(e) => {
                      e.target.src = "/empty_profile_male.jpeg";
                    }}
                  />
                  <Card.Body>
                    <Card.Title>{candidate?.name}</Card.Title>
                    <Card.Text>Gender: {candidate?.gender}</Card.Text>
                    <input
                      type="checkbox"
                      id={`candidate-${candidate.id}`}
                      checked={selectedCandidates.includes(candidate.id)}
                      onChange={() => handleCheckboxChange(candidate.id)}
                    />
                    <label
                      htmlFor={`candidate-${candidate.id}`}
                      style={{
                        marginLeft: "8px",
                        color: selectedCandidates.includes(candidate.id)
                          ? "blue"
                          : "black",
                        fontWeight: selectedCandidates.includes(candidate.id)
                          ? "bold"
                          : "normal",
                        fontSize: selectedCandidates.includes(candidate.id)
                          ? "1.4em"
                          : "1.2em",
                      }}
                    >
                      Vote | Tora
                    </label>
                  </Card.Body>
                </Card>
              ))}
          </div>
        </div>
      )}
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
        <p></p>
        <Button variant="secondary" onClick={handleskip}>
          Blank Vote | Ndifashe
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Next | Komeza
        </Button>
      </div>
      {/* Modal for confirmation */}
      <Modal show={showModal} onHide={handleModalCancel}>
        <Modal.Header>
          <Modal.Title>Emeza</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCandidates?.length > 0 && (
            <>
              <p>Watoye</p>
              <p>You have voted for:</p>
              <p>
                {selectedCandidates?.map((candidateId, index) => {
                  const candidate = getCandidateById(candidateId);
                  return (
                    <span key={index}>
                      <b>{candidate?.name}</b>
                      {index < selectedCandidates.length - 1 && ", "}
                    </span>
                  );
                })}
              </p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalCancel}>
            Oya Hagarika | cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleModalConfirm}
            // Disable the button if loading
            disabled={loading}
          >
            {loading ? "Loading..." : "Yes | Yego"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal for confirmation */}
      <Modal show={showSkipModal} onHide={handleModalCancel}>
        <Modal.Header>
          <Modal.Title>Confirm | Emaza</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you want to submit empty Vote?
          <p>Ntamukandida utoye Urifashe?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalCancel}>
            Cancel | Hagarika
          </Button>
          <Button
            variant="primary"
            onClick={handleSkip}
            // Disable the button if loading
            disabled={loading}
          >
            {loading ? "Loading..." : "Yes | Yego"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal for confirmation */}
      <Modal show={showResponseModal} onHide={handleModalCancel2}>
        <Modal.Header>
          <Modal.Title>{loading ? "Kohereza...." : "Amatora"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: responseMessage || "Ntagisubizo kibonetse",
              }}
            />
          )}
        </Modal.Body>

        <Modal.Footer>
          {!loading && (
            <Button variant="primary" onClick={handleModalCancel2}>
              Subira ahabanza | Go Back Home
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VotingPage;
