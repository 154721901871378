import React from "react";
import { Navbar, Container } from "react-bootstrap";

const TopBar = () => {
  return (
    <Navbar style={{ backgroundColor: "#0f8fd4" }} variant="dark"></Navbar>
  );
};

export default TopBar;
