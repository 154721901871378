import React, { useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Navbar } from "react-bootstrap";
import TopBar from "./TopBar";
//const BASE_URL = "https://evoting-backend.qtsoftwareltd.com:4435";
//const BASE_URL = "http://10.20.5.150";
import { BASE_URL } from "./config";
const HomePage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    no: "",
    code: "",
  });
  var data;
  const [loading, setLoading] = useState(false);
  const [positionDetail, setPositionDetail] = useState(null);
  const [responseMessage, setResponseMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      // Call API with formData
      const response = await fetch(`${BASE_URL}/v1/mobile/start`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      data = await response.json();
      console.log(data);
      //console.log(data.data.position);
      if (data.error) {
        setResponseMessage(data.message);
        setShowModal(true);
      } else {
        setPositionDetail(data?.data);
        navigate("/voting", {
          state: {
            no: formData.no.toUpperCase(),
            //code: formData.code,
            // code: "0000",
            positionDetail: data?.data,
          },
        });
      }
    } catch (error) {
      console.log("data1", error);
      if (
        error.toString().includes("TypeError: Failed to fetch") ||
        error.toString().includes("TypeError: Load failed")
      ) {
        setResponseMessage(`Ikibazo cy'ihuzanzira(Network issue)`);
      } else {
        setResponseMessage(`${error}`);
      }

      setShowModal(true);
    } finally {
      setLoading(false);
    }
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(remainingSeconds).padStart(2, "0")}`;
  };
  // Function to handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle back button click
  const handleBack = () => {
    setPositionDetail(null);
  };

  const handleModalConfirm = async () => {
    setShowModal(false);
  };
  // Function to handle continue button click
  const handleContinue = () => {
    // Navigate to the Voting page and pass data using state
    if (positionDetail) {
      console.log("Position Detail:", positionDetail);
      navigate("/voting", {
        state: {
          no: formData.no.toUpperCase(),
          //code: formData.code,
          // code: "0000",
          positionDetail: positionDetail,
        },
      });
    } else {
      console.error("Position detail is null.");
    }
  };

  return (
    <div>
      <Navbar style={{ backgroundColor: "#0f8fd4" }} variant="dark"></Navbar>
      {
        <header className="d-flex justify-content-between align-items-center mb-4">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "20px",
            }}
          >
            <img
              src="/rwanda_img.svg"
              alt="Logo"
              style={{ width: "60px", height: "60px", marginRight: "10px" }}
            />
            <div>
              <h2 style={{ marginBottom: "2px" }}>SENATE</h2>{" "}
              {/* Reduced bottom margin */}
              <p
                style={{
                  margin: 0,
                  fontSize: "12px",
                  fontFamily: "Comic Sans MS",
                }}
              >
                E-Voting Platform
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="/Voting_logo.png"
              alt="Logo"
              style={{
                width: "60px",
                height: "60px",
                marginBottom: "10px",
                marginRight: "10px",
              }}
            />
            <span
              style={{
                margin: 0,
                fontSize: "12px",
                fontFamily: "Comic Sans MS",
              }}
            >
              Amatora
            </span>
          </div>
        </header>
      }
      <div>
        {loading && (
          <div className="d-flex  justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <p>Loading...</p>
          </div>
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {positionDetail ? (
          // Display position detail if available
          <div style={{ padding: "20px" }}>
            {/* <h6>Position/ Umwanya Upiganirwa: </h6>
          <h6>{positionDetail?.position?.name}</h6>
          <p>
            No. of Candidates/ Abakandida:{" "}
            {positionDetail?.position?.no_of_candidates}
          </p>
          <p>
            No. of Selections/ Umubare ugomba gutorwa :{" "}
            {positionDetail?.position?.no_of_selections}
          </p>
          {positionDetail?.position?.no_of_selections_female !== 0 && (
            <p>
              No. of Female Selections/ Abadamu:{" "}
              {positionDetail?.position?.no_of_selections_female}
            </p>
          )}

          <p>
            Session Duration/ Akanya ntarengwa:{" "}
            {formatTime(positionDetail?.position?.session_duration)}
          </p> */}
            {/* <p>Instructions/ Amabwiriza: </p> */}
            <div
              dangerouslySetInnerHTML={{
                __html: positionDetail?.position?.instructions,
              }}
            />
            <div
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              <button className="btn btn-primary" onClick={handleBack}>
                &lt; Back | Subira Inyama
              </button>
              <button className="btn btn-primary" onClick={handleContinue}>
                Next | Komeza &gt;
              </button>
            </div>
          </div>
        ) : (
          // Display form if position detail is not available

          <form onSubmit={handleSubmit} style={{ textAlign: "center" }}>
            <div className="mb-3" style={{ padding: "10px" }}>
              <label htmlFor="no" className="form-label">
                <b>Kode yawe y'itora</b>
                <br />
                <i>Your Voting code</i>
              </label>
              <div className="input-group" style={{ padding: "5px" }}>
                <span className="input-group-text">
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <input
                  name="no"
                  className="form-control"
                  id="no"
                  value={formData.no}
                  onChange={handleChange}
                  maxLength={4}
                  minLength={4}
                  required
                  style={{ padding: "10px", maxWidth: "500px" }}
                />
              </div>
            </div>

            {/* <div className="mb-3" style={{ padding: "10px" }}>
            <label htmlFor="code" className="form-label">
              <b>Kode iranga umwanya upiganirwa</b>
              <br />
              <i>Position-Session code</i>
            </label>
            <div className="input-group" style={{ padding: "5px" }}>
              <span className="input-group-text">
                <FontAwesomeIcon icon={faUsers} />
              </span>
              <input
                type="number"
                name="code"
                className="form-control"
                id="code"
                value={formData.code}
                onChange={handleChange}
                maxLength={7}
                minLength={4}
                pattern="\d*"
                required
                style={{ padding: "10px" }}
              />
            </div>
          </div> */}

            <div style={{ padding: "10px" }}>
              <button type="submit" className="btn btn-primary">
                Next | Komeza &gt;
              </button>
            </div>
          </form>
        )}
      </div>
      {/* Modal for confirmation */}
      <Modal show={showModal}>
        <Modal.Header>
          <Modal.Title>Amatora</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {responseMessage?.toLowerCase()?.includes("osition not found") && (
            <p>Umwanya upiganirwa ntubashije kuboneka</p>
          )}
          {responseMessage?.toLowerCase().includes("have already voted") && (
            <p>Washoje Gutora kuri uyu mwanya</p>
          )}
          {responseMessage?.toLowerCase().includes("ode not found") && (
            <p>Kode yawe ntibashije kuboneka</p>
          )}
          {responseMessage?.includes("code: Invalid value") && (
            <p>Kode yawe ntago yuzuye</p>
          )}
          {responseMessage
            ?.toLowerCase()
            .includes("ode not allowed to vote for this position") && (
            <p>Kode yawe ntiyemerewe gutora kuri Uyu mwanya</p>
          )}
          <p>{responseMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleModalConfirm}
            // Disable the button if loading
            disabled={loading}
          >
            {loading ? "Loading..." : "Funga | Close"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HomePage;
