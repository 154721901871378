// App.jsx or main routing component
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import HomePage from "./HomePage";
import VotingPage from "./VotingPage";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/voting" element={<VotingPage />} />
      </Routes>
    </Router>
  );
};

export default App;
